////
///
/// Alert default variables
///
/// @group elements
/// @author Clint Kolodziej
///
////

$alert-color: inherit !default;
$alert-background-color: #fcf8e3 !default;
$alert-border-color: #fbeed5 !default;

$alert-success-color: #468847 !default;
$alert-success-background-color: #dff0d8 !default;
$alert-success-border-color: #d6e9c6 !default;

$alert-error-color: #b94a48 !default;
$alert-error-background-color: #f2dede !default;
$alert-error-border-color: #eed3d7 !default;