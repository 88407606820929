////
///
/// Hint default variables
///
/// @group elements
/// @author Clint Kolodziej
///
////

$hint-color: #fff !default;
$hint-background-color: #444 !default;

$hint-invalid-color: #fff !default;
$hint-invalid-background-color: red !default;