#login .alert.validation-summary-errors {
  display: none;
  padding: 8px 14px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: .25em;
  color: inherit;
}

#login .show.alert.validation-summary-errors {
  display: block;
}

#login .alert.validation-summary-errors {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

#login .field {
  position: relative;
  display: block;
}

#login .field label {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#login .field {
  float: none;
  margin: 1.625em 0;
  padding: 0;
}

#login .field label {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
}

#login .hint {
  box-sizing: border-box;
  position: absolute;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  background: #444;
  color: #fff;
  padding: .25em .5em;
  margin-left: .75em;
  text-align: center;
  z-index: 1;
}

#login .hint:empty {
  visibility: hidden !important;
}

#login .hint:before {
  content: " ";
  position: absolute;
  top: 1em;
  left: -1em;
  opacity: 0;
  width: 0;
  height: 0;
  margin-top: -.5em;
  border-width: .5em;
  border-style: solid;
  border-color: #444;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

#login *:invalid + .hint {
  color: #fff;
  background: red;
}

#login *:invalid + .hint:before {
  border-right-color: red;
}

#login *:focus + .hint {
  visibility: visible;
  opacity: 1;
}

#login *:focus + .hint:before {
  opacity: 1;
}

#login .hint {
  top: 100%;
  margin: 0;
  margin-top: .75em;
}

#login .hint:before {
  top: -14px;
  left: 50%;
  margin-top: 0;
  margin-left: -7px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #444;
}

#login *:invalid + .hint:before {
  border-right-color: transparent;
  border-bottom-color: red;
}

#login form {
  text-align: left;
  flex-basis: auto;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

@media screen and (min-width: 41.5rem) {
  #login form {
    flex-basis: auto;
    margin-left: 5.75rem;
    margin-right: 5.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

@media screen and (min-width: 61.5rem) {
  #login form {
    flex-basis: auto;
    margin-left: 15.75rem;
    margin-right: 15.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

@media screen and (min-width: 81.5rem) {
  #login form {
    flex-basis: auto;
    margin-left: 25.75rem;
    margin-right: 25.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

#login form input {
  width: 100%;
}

#login p {
  text-align: right;
}

#login .alert ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#login .alert.validation-summary-errors {
  display: block;
}
