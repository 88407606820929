////
///
/// Hint placeholders
///
/// @group hint
/// @author Clint Kolodziej
///
////

@import "../modules/grid";
@import "../defaults/hint";

//
// Hint default
//

%hint {

	box-sizing: border-box;
	position: absolute;
	display: inline-block;
	visibility: hidden;
	opacity: 0;
	background: $hint-background-color;
	color: $hint-color;
	padding: .25em .5em;
	margin-left: .75em;
	text-align: center;
	//transition: all .25s linear;
	z-index: 1;

	&:empty {
		visibility: hidden !important;
	}

	&:before {
		content: " ";
		position: absolute;
		top: 1em;
		left: -1em;
		opacity: 0;
		width: 0;
		height: 0;
		margin-top: -.5em;
		border-width: .5em;
		border-style: solid;
		border-color: $hint-background-color;
		border-top-color: transparent;
		border-left-color: transparent;
		border-bottom-color: transparent;
		//transition: all .25s linear;
	}

	*:invalid + & {
		color: $hint-invalid-color;
		background: $hint-invalid-background-color;

		&:before {
			border-right-color: $hint-invalid-background-color;
		}
	}

	*:focus + & {
		visibility: visible;
		opacity: 1;

		&:before {
			opacity: 1;
		}
	}
}

//
// Hint in block display
//

%hint-block {
	top: auto;
	margin: 0;
	margin-left: .75em;

	&:before {
		top: 1em;
		left: -14px;
		margin-top: -7px;
		margin-left: 0;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: $hint-background-color;
		border-bottom-color: transparent;
	}

}

*:invalid + %hint-block:before {
	border-right-color: $hint-invalid-background-color;
	border-bottom-color: transparent;
}

//
// Hint in inline display
//

%hint-inline {
	top: 100%;
	margin: 0;
	margin-top: .75em;

	&:before {
		top: -14px;
		left: 50%;
		margin-top: 0;
		margin-left: -7px;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
		border-bottom-color: $hint-background-color;
	}

}

*:invalid + %hint-inline:before {
	border-right-color: transparent;
	border-bottom-color: $hint-invalid-background-color;
}

//
// Hint in aligned display
//

%hint-align {
	top: auto;
	margin: 0;
	margin-left: .75em;

	&:before {
		top: 1em;
		left: -14px;
		margin-top: -7px;
		margin-left: 0;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: $hint-background-color;
		border-bottom-color: transparent;
	}

}

*:invalid + %hint-align:before {
	border-right-color: $hint-invalid-background-color;
	border-bottom-color: transparent;
}