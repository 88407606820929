@import "site/settings";
@import "watercolor/modules/grid";
@import "watercolor/elements/alert";
@import "watercolor/elements/field";
@import "watercolor/elements/hint";

//
// Login
//

#login {

	form {
		text-align: left;

		@include column;

		@include breakpoint(medium) {
            @include column($prefix: 1, $suffix: 1);
        }

		@include breakpoint(large) {
            @include column($prefix: 3, $suffix: 3);
        }

		@include breakpoint(extra-large) {
            @include column($prefix: 5, $suffix: 5);
        }

		input {
			width: 100%;
		}
	}

	p {
		text-align: right;
	}

	.field {
		@include field;
	}

	.hint {
		@include hint(inline);
	}

	.alert {

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		&.validation-summary-errors {
			display: block;
			@include alert(error);
		}
	}
}